import React from "react"
import { Link } from "gatsby"
import '../styles/utilitary.css'
import '../styles/recipe2.css'

import $ from 'jquery' // important: case sensitive.
import cardfoto from "../images/carne2.png" // Tell webpack this JS file uses this image
import recipeicon from "../images/recipe-icon.png" // Tell webpack this JS file uses this image

import Layout from "../components/layout"
import SEO from "../components/seo"
import cardfoto1 from "../images/BacalhauaBraz_baixa.jpg" // Tell webpack this JS file uses this image
import cardfoto2 from "../images/BacalhauLegumes_baixa.jpg" // Tell webpack this JS file uses this image
import cardfoto3 from "../images/BacalhaucomGrao_baixa.jpg" // Tell webpack this JS file uses this image
import iconnutri from "../images/icon-nutri.png" // Tell webpack this JS file uses this image



const ReceitaB = () => (
  <Layout>
    <SEO title="Bacalhau Mil e Uma Maneiras - Receita 2" />

    <div id="rec2-section-1">
        <div className="wrapper-foto">
    </div>

        <div className="wrapper">
        <div className="titulo playfair-black">
            Bacalhau e legumes grelhados
        </div>
        <div className="desc sneak-regular">
        Aliamos o bacalhau aos legumes grelhados: uma receita requintada, mas fácil e rápida.
        </div>
        </div>
    </div>
    <div id="rec2-section-2">
        <div className="wrapper">
            <div className="left-col">
                <div className="card"> 
                <div className="contentor">
                    <div className="foto">
                    {/* <img src={cardfoto} alt="Logo" /> */}

                    </div>
                    <div className="title absara">
                        Ingredientes
                    </div>
                    <div className="ingrediente ">
                        <div className="number absara">
                            1
                        </div>
                        <div className="text sneak-regular">  
                         Emb. de filetes de Bacalhau Ponto de Sal
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            1 
                        </div>
                        <div className="text sneak-regular">  
                         Cebola roxa
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            1 
                        </div>
                        <div className="text sneak-regular">  
                        Pimento amarelo
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            1 
                        </div>
                        <div className="text sneak-regular">  
                         Courgete
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            1 
                        </div>
                        <div className="text sneak-regular">  
                         Cacho de tomates cherry
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            Q/B 
                        </div>
                        <div className="text sneak-regular">  
                         Pimentos Padron
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            Q/B 
                        </div>
                        <div className="text sneak-regular">  
                         Sal, oregãos e pimenta
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            Q/B 
                        </div>
                        <div className="text sneak-regular">  
                        Azeite
                        </div>
                        
                    </div>
                  
                    
                    
                </div>
               
                </div>
            </div>
            <div className="right-col">

            <div className="card">
                    <div className="contentor">
                    <div className="foto">
                    {/* <img src={cardfoto} alt="Logo" /> */}

                    </div>
                    <div className="title absara">
                        Receita
                    </div>
                    <div className="ingrediente ">
                        <div className="number absara">
                            1 - 
                        </div>
                        <div className="text sneak-regular">  
                        Tempere o bacalhau com sal, pimenta e azeite e coloque a grelhar.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            2 - 
                        </div>
                        <div className="text sneak-regular">  
                        Entretanto, corte a cebola, o pimento e a courgete em rodelas grossas, tempere e disponha igualmente na grelha com o cacho de tomate cherry.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            3 - 
                        </div>
                        <div className="text sneak-regular">  
                        Retire os ingredientes após confeção e disponha num prato.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            4 - 
                        </div>
                        <div className="text sneak-regular">  
                        Polvilhe com orégãos e regue com azeite quente.                      
                          </div>
                        
                    </div>
       
                  
                 
                    
                </div>
               </div>
            </div>
        </div>
    </div>
    <div id="section-nutri">
    <div className="caracts">
            
            <div className="wrapper">
           
                <div className="card-caracts">
                    <div className="icon-nutri">
                    <img src={iconnutri} alt="ingredient" />

                    </div>
                <div className="main-titulo absara">
                   Valores Nutricionais
                </div>
                <div className="contentor">
                <div className="col-1">
                        <div className="titulo absara">
                            Energia Kcal
                        </div>
                        <div className="titulo absara">
                            Lipidos g
                        </div>
                        <div className="titulo absara">
                            Saturados g
                        </div>
                        <div className="titulo absara">
                            Hidratos g
                        </div>
                        <div className="titulo absara">
                            Açucares g
                        </div>
                        <div className="titulo absara">
                            Proteinas g
                        </div>
                        <div className="titulo absara">
                            Fibra g
                        </div>
                     
                    </div>
                    <div className="col-2">
                    <div className="titulo sneak-regular">
                            297 
                        </div>
                        <div className="titulo sneak-regular">
                            8
                        </div>
                        <div className="titulo sneak-regular">
                           1
                        </div>
                        <div className="titulo sneak-regular">
                          14
                        </div>
                        <div className="titulo sneak-regular">
                         13
                        </div>
                        <div className="titulo sneak-regular">
                          42
                        </div>
                        <div className="titulo sneak-regular">
                         7
                        </div>
                       
                        </div>
                </div>
                   
                  
                    
                    
                </div>
            </div>
        </div>
    </div>
  


  </Layout>
)


export default ReceitaB
